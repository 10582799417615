import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { OrderResponse } from 'src/model/OrderResponse';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { TicketsUI } from './ui';

interface EventParam {
  id: string;
}

export const TicketsScreens = () => {
  const [order, setOrder] = useState<OrderResponse>();
  const { id } = useParams<EventParam>();
  const dispatch = useDispatch();

  const handleOrder = async (): Promise<void> => {
    console.log(id);
    try {
      dispatch(setLoading(true));
      const response = await api.get<OrderResponse>(`/order/${id}`);

      console.log(response);
      if (response) {
        setOrder(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar o Inscrição:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handlePaymentStatus = (status: string): string => {
    switch (status) {
      case 'APPROVED':
        return 'Aprovado';
      case 'DISAPPROVED':
        return 'Rejeitado';
      case 'AWAITING_PAYMENT':
        return 'Aguardando Pagamento';
      case 'CANCELED':
        return 'Cancelado';
      case 'CANCELLATION_ANALYSIS':
        return 'Análise de Cancelamento';
      case 'EXPIRED':
        return 'Expirado';
      default:
        return 'Status Desconhecido';
    }
  };

  useEffect(() => {
    handleOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {order ? (
        <TicketsUI order={order} paymentStatus={handlePaymentStatus} />
      ) : (
        <div>Carregando...</div>
      )}
    </>
  );
};
