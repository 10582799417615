import React from 'react';
import { AuthWrapper } from 'src/features/core/auth/screens/AuthWrapper';
import { GroupUI } from './ui';

export const GroupScreen: React.FC = (): JSX.Element => {
  return (
    <AuthWrapper isHomepage={false}>
      <GroupUI />
    </AuthWrapper>
  );
};
