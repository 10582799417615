import React, { FormEvent } from 'react';
import { Button } from 'src/components/Button';
import { InputFile } from 'src/components/InputFile';
import { InputText } from 'src/components/InputText';
import { AuthWrapper } from 'src/features/core/auth/screens/AuthWrapper';
import { FromInputRegisterPix } from 'src/features/core/auth/types';
import { UseFormReturn } from 'src/hooks';

export interface NameFiles {
  [key: string]: string;
}

export interface CreatePixProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  formNameFiles?: NameFiles;
  state: boolean;
  onSubmitRegister: (e: FormEvent) => void;
  onChangeFormFileInput: (
    inputName: string
  ) => (file: File | undefined) => void;
}

export const CreatePixUI: React.FC<CreatePixProps> = ({
  formData,
  state,
  formErrors,
  onChangeFormInput,
  formNameFiles,
  onSubmitRegister,
  onChangeFormFileInput,
}): JSX.Element => {
  const imageBase64Ref = React.useRef<HTMLInputElement>(null);

  return (
    <AuthWrapper isHomepage={false}>
      <section className="p-4 space-y-[70px] mt-[15px] mb-[100px] max-w-5xl mx-auto ">
        <form onSubmit={onSubmitRegister}>
          <div className="mt-5 pt-5 max-w-5xl mx-auto border border-gray-100 rounded shadow-outline pb-6">
            <div className="ml-5">
              <h1 className="text-gray-700 text-2xl font-dmsans font-bold mb-10">
                Cadastro de PIX
              </h1>
            </div>
            <div className="p-3 max-w-4xl mx-auto">
              <div>
                <InputText
                  name="name"
                  label="Numero do PIX"
                  placeholder="Digite aqui..."
                  maxLength={100}
                  value={formData[FromInputRegisterPix.numeroPix]}
                  onChange={(e) =>
                    onChangeFormInput(FromInputRegisterPix.numeroPix)(
                      e.target.value
                    )
                  }
                  error={formErrors.name && formErrors.name[0]}
                />
                <div className="mb-5 mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
                  <InputFile
                    refInput={imageBase64Ref}
                    name="imageBase64"
                    label="Foto"
                    fileName={formNameFiles?.imageBase64}
                    onChange={(e) =>
                      onChangeFormFileInput(FromInputRegisterPix.imageBase64)(
                        (e.target as HTMLInputElement)?.files?.[0]
                      )
                    }
                    error={formErrors.imageBase64 && formErrors.imageBase64[0]}
                  />
                </div>
              </div>
            </div>
            <div className="p-3 max-w-4xl mx-auto">
              <Button
                type="submit"
                title="Registrar Pix"
                buttonStyle="primary"
                size="xlg"
                className="w-full"
                disabled={
                  formData[FromInputRegisterPix.numeroPix] === '' ||
                  formData[FromInputRegisterPix.imageBase64] === '' ||
                  state
                }
              />
            </div>
          </div>
        </form>
      </section>
    </AuthWrapper>
  );
};
