import * as Dialog from '@radix-ui/react-dialog';
// import { useState } from 'react';
import {
  // CaretDown,
  Close,
  // Sizes
} from '../../assets/icons';
import { classNames } from '../../helpers/common';
import { getBoolean } from '../../helpers/common/localStorage';
// import { colors } from '../../styles/colors';
import { REACT_APP_AUTH } from '../../utils/config';
import { useState } from 'react';

export interface SidebarProps {
  visible: boolean;
  onSetVisible?: (open: boolean) => void;
  handleOnSignIn?: () => void;
  handleOnSignUp?: () => void;
  handleOnLogout?: () => void;
  goTo: (href: string) => void;
}

export const Menu = ({
  visible,
  onSetVisible,
  // handleOnSignIn,
  // handleOnSignUp,
  // handleOnLogout,
  goTo,
}: SidebarProps) => {
  const [open, setOpen] = useState(false);
  const signed = getBoolean(String(REACT_APP_AUTH));

  return (
    <Dialog.Root open={visible} onOpenChange={onSetVisible}>
      <Dialog.Portal className="relative z-10">
        <Dialog.Overlay className="fixed inset-0 bg-modal-overlay">
          <Dialog.Content className="fixed inset-0 z-10">
            <div
              className="flex h-full items-start justify-end text-left sm:items-center sm:p-0
            md:radix-state-closed:translate-x-[244px]
            md:radix-state-open:translate-x-0 animate-enter-from-right"
            >
              <div
                className="relative z-50 pt-[46px] w-full h-full md:w-[244px]
                bg-white font-dmsans shadow-xl rounded-lg"
              >
                <div className="flex flex-col justify-between items-center">
                  <div className="self-end p-0 pr-[40px]">
                    <Dialog.Close asChild>
                      <img
                        src={Close}
                        className="cursor-pointer"
                        alt="button closer"
                      />
                    </Dialog.Close>
                  </div>
                </div>
                <nav className="flex flex-col mx-10 font-dmsans">
                  <a
                    href="#"
                    tabIndex={-1}
                    onClick={() => goTo('/')}
                    className="text-base font-bold mb-[17px] cursor-pointer"
                  >
                    Inicio
                  </a>
                  <a
                    href="#"
                    tabIndex={-1}
                    onClick={() => goTo('/historia')}
                    className="text-base font-bold mb-[17px] cursor-pointer"
                  >
                    História
                  </a>
                  <a
                    className="text-base font-bold mb-[17px] cursor-pointer"
                    href="#"
                    // onClick={() => {
                    //   goTo('#');
                    //   onSetVisible && onSetVisible(false);
                    // }}
                  >
                    O Grupo
                  </a>
                  <a
                    className="text-base font-bold mb-[17px] ml-2 cursor-pointer"
                    onClick={() => {
                      goTo('/mestre-dionizio');
                    }}
                  >
                    Mestre Dionizio
                  </a>
                  <a
                    className="text-base font-bold ml-2 mb-[17px] cursor-pointer"
                    onClick={() => {
                      goTo('/graduacao');
                      onSetVisible && onSetVisible(false);
                    }}
                  >
                    Sistema de Graduação
                  </a>
                  <a
                    href="#"
                    tabIndex={-1}
                    onClick={() => {
                      goTo('/eventos');
                    }}
                    className="text-base font-bold mb-[17px] cursor-pointer"
                  >
                    Eventos
                  </a>
                  <a
                    href="#"
                    tabIndex={-1}
                    onClick={() => {
                      goTo('/articles');
                    }}
                    className="text-base font-bold mb-[17px] cursor-pointer"
                  >
                    Artigos
                  </a>
                  <a
                    href="#"
                    tabIndex={-1}
                    onClick={() => {
                      goTo('#');
                    }}
                    className="text-base font-bold mb-[17px] cursor-pointer"
                  >
                    Mídia
                  </a>
                  <a
                    href="#"
                    tabIndex={-1}
                    onClick={() => {
                      goTo('#');
                    }}
                    className="text-base font-bold mb-[17px] cursor-pointer"
                  >
                    Contato
                  </a>
                </nav>

                {/* (
                  <nav className="flex flex-col mx-10 font-dmsans">
                    <a
                      href="#"
                      className="text-base font-bold mb-[17px] flex"
                      onClick={() => setOpen(!open)}
                    >
                      <span className="mr-3">Minha conta</span>
                      <CaretDown
                        style={{
                          transform: open ? 'rotate(-180deg)' : 'rotate(0deg)',
                        }}
                        size={Sizes.xmedium}
                        color={colors.graylight}
                      />
                    </a>
                    {open && (
                      <nav className="flex flex-col mx-5 font-dmsans"></nav>
                    )}
                  </nav>
                ) : */}

                {!signed && (
                  <nav className="flex flex-col text-center mx-10 font-dmsans mt-[40px]">
                    {/* <button
                      id="btnDoLogin"
                      className={classNames(
                        'px-10 py-2 transition-colors duration-150 rounded-lg focus:shadow-outline bg-neutro-b-400 hover:bg-neutro-b-600 active:bg-neutro-b-400 text-base font-bold font-dmsans text-white'
                      )}
                      // onClick={handleOnSignIn}
                    >
                      Entrar
                    </button> */}

                    <a
                      href="#"
                      className={classNames(
                        'font-dmsans bg-white text-black font-bold p-5'
                      )}
                      onClick={() => goTo('/criar-conta')}
                    >
                      Criar conta
                    </a>
                  </nav>
                )}
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
