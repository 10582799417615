import React from 'react';
import { Route } from 'src/navigation/Route';
import { TicketsScreens } from './screens/Tickets';

export const TICKETS_ROUTES = {
  tickets: '/ingressos/:id',
};

export const TicketsNavigation = () => {
  return (
    <React.Fragment>
      <Route path={TICKETS_ROUTES.tickets} exact component={TicketsScreens} />
    </React.Fragment>
  );
};
