import React from 'react';
import { Route } from '../../navigation/Route';
import { CreateAccountScreen } from './Screens/CreateAccount';

export const CREATEACCOUNT_ROUTES = {
  createAccount: '/criar-conta',
};
export const CreateAccountnavigation = (): JSX.Element => (
  <React.Fragment>
    <Route
      path={CREATEACCOUNT_ROUTES.createAccount}
      exact
      component={CreateAccountScreen}
    />
  </React.Fragment>
);
