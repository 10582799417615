import React from 'react';
import logo from '../../../../../assets/images/NGOLO_PRETA.png';
// eslint-disable-next-line prettier/prettier
import { TrainingSession } from '../../../components/TrainingSession';

export const HomeContainer: React.FC<object> = () => {
  return (
    <>
      <section className="w-full h-[550px] md:bg-hero bg-hero-mobile  bg-cover bg-center">
        <div className="container w-full md:max-w-[1110px] h-full mx-auto flex flex-col items-center pt-20 sm:pt-44"></div>
      </section>
      <section className="w-full">
        <div className="container w-full md:max-w-[1110px] mx-auto flex flex-col items-center">
          <div className="p-8 md:flex-shrink-0">
            <div className="text-[#013209] font-extrabold text-3xl sm:text-4xl lg:text-5xl tracking-tight text-center dark:text-white ">
              Centro Cultural Arte Luta N&apos;golo Capoeira
            </div>
            <div className="mt-2 flex flex-col md:flex-row items-center">
              <div className="md:w-2/4 text-lg">
                <p className="mt-6 text-[#013209] text-center max-w-3xl mx-auto dark:text-slate-400">
                  O Centro Cultural Arte Luta N&apos;GOLO Capoeira é uma
                  entidade sem fins lucrativos constituída juridicamente, que
                  tem como finalidade o ensino e a difusão da capoeira como
                  prática educativa, cultural e desportiva.
                </p>
                <p className="mt-3 text-[#013209] text-center max-w-3xl mx-auto dark:text-slate-400">
                  Com sede em Brasília e filiais em São Paulo, Pernambuco,
                  Paraíba, Maranhão, Goiás, e Thailândia, o corpo docente do
                  N&apos;GOLO Capoeira é, constituído por profissionais com
                  experiência comprovada, devidamente qualificados e
                  habilitados.
                </p>
                <p className="mt-3 text-[#013209] text-center max-w-3xl mx-auto dark:text-slate-400">
                  Venha treinar capoeira com a gente!
                </p>
              </div>
              <div className="md:w-2/4">
                <img
                  className="mx-auto mt-6 md:mt-0"
                  src={logo}
                  alt="Descrição da imagem"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w-full bg-[#effef0] pt-5 pb-5">
        <div className="md:max-w-[1110px] mx-auto">
          <div className="text-slate-900 font-extrabold text-1xl sm:text-1xl lg:text-3xl tracking-tight text-[#013209] md:max-w-[1110px]">
            Venha fazer parte da nossa história!
          </div>
          <div className="p-8 md:flex-shrink-0 text-2xl">
            <p className="text-[#013209]">
              O Centro Cultural Arte Luta N&apos;GOLO Capoeira é uma entidade
              sem fins lucrativos constituída juridicamente, que tem como
              finalidade o ensino e a difusão da capoeira como prática
              educativa, cultural e desportiva.
            </p>
            <p className="text-[#013209] mt-3">
              Com sede em Brasília e filiais em{' '}
              <b>
                São Paulo, Pernambuco, Paraíba, Maranhão, Goiás, e Thailândia,
              </b>
              o corpo docente do N&apos;GOLO Capoeira é, constituído por
              profissionais com experiência comprovada, devidamente qualificados
              e habilitados.
            </p>
          </div>
        </div>
      </section>
      <section className="w-full">
        <div className="container w-full md:max-w-[1110px] mx-auto flex flex-col items-center">
          <div className="p-8 md:flex-shrink-0">
            <div className="text-[#013209] font-extrabold text-1xl sm:text-1xl text-center lg:text-3xl text- tracking-tight dark:text-white md:max-w-[1110px]">
              Onde Treinar!
            </div>
            <div className="mt-2  items-center"></div>
          </div>
          <div>
            <TrainingSession />
          </div>
        </div>
      </section>
    </>
  );
};
