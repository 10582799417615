import React from 'react';
import { Button } from 'src/components/Button';
import { Events } from 'src/model/Event';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import DOMPurify from 'dompurify';

dayjs.extend(utc);

interface EventUIProps {
  event: Events;
  onRegister: (id: string) => void;
}

export const EventUI: React.FC<EventUIProps> = ({ event, onRegister }) => {
  if (!event) {
    return null;
  }

  const formattedStartDate = dayjs.utc(event.startDate).format('DD/MM/YYYY');
  const formattedEndDate = dayjs.utc(event.endDate).format('DD/MM/YYYY');
  const sanitizedDescription = DOMPurify.sanitize(event.description);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-hidden max-w-4xl w-full">
        <div className="md:w-1/2 flex items-center justify-center">
          <img
            src={'https://api.ngolocapoeira.org/uploads/' + event.image}
            alt={event.name}
            className="w-auto h-500 rounded pl-3"
          />
        </div>
        <div className="md:w-1/2 p-6 flex flex-col justify-center">
          <h1 className="text-3xl md:text-4xl font-bold text-gray-800 mb-4">
            {event.name}
          </h1>
          <div
            className="text-lg md:text-sm text-gray-700 mb-4"
            dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
          />
          <p className="text-lg text-gray-600 mb-6">
            <span className="font-bold">Data:</span> {formattedStartDate} à{' '}
            {formattedEndDate}
          </p>
          <Button
            title="Inscrição"
            buttonStyle="primary"
            size="xlg"
            className="w-full max-w-xs mx-auto"
            onClick={() => onRegister(event.id)}
          />
        </div>
      </div>
    </div>
  );
};
