import React, { MouseEventHandler, useState } from 'react';
import { IconCalendar } from 'src/assets/icons';
import mestre from 'src/assets/images/Professores/dionisio.png';
import digao from 'src/assets/images/Professores/DIGAO.png';
import lia from 'src/assets/images/Professores/LIA.png';
import pesado from 'src/assets/images/Professores/PESADO.png';
import dani from 'src/assets/images/Professores/DANI.png';
import beto from 'src/assets/images/Professores/Beto.png';
import toso from 'src/assets/images/Professores/toso.png';
import paulada from 'src/assets/images/Professores/Paullada.png';
import sabugao from 'src/assets/images/Professores/SABUGGAO.png';
import terzi from 'src/assets/images/Professores/TERZI.png';
import { MasonryUI } from 'src/components/Mansory';
import macarrao from 'src/assets/images/Professores/MACARRÃO.png';
import marrudo from 'src/assets/images/Professores/MARRUDO.png';
import natalino from 'src/assets/images/Professores/natalino.png';

interface Professores {
  professor: string;
  phone: string;
  local: end[];
  foto: string;
}
interface end {
  local: string;
  end: string;
  turma: turma[];
}
interface turma {
  horas: string;
  turma: string;
  dias: string;
}
interface City {
  state: string;
  aulas: Professores[];
}

export const TrainingSession = () => {
  const [state, setState] = useState<string>('Brasilia');
  const [colun, setcolun] = useState<number>(2);

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    const nome = event.currentTarget.textContent; // Obtém o texto do elemento clicado
    console.log('Clicou em:', nome);

    nome ? setState(nome) : '';
  };

  const professores: City[] = [
    {
      state: 'Brasilia',
      aulas: [
        {
          professor: 'Mestre Dionízio',
          phone: '(61) 9 9221-6835',
          local: [
            {
              local: 'CENTRO ENSINO MÉDIO 01',
              end: 'CEM 01 DO GUARÁ (GG) QE 07 Área Especial M - GUARÁ I',
              turma: [
                {
                  horas: '19h30',
                  turma: 'Juvenil e Adulto',
                  dias: 'seg. qua. sex.',
                },
              ],
            },
          ],
          foto: mestre,
        },
        {
          professor: 'Mestre Digão',
          phone: '(61) 9 8586-5413',
          local: [
            {
              local: 'CENTRO ENSINO MÉDIO 01',
              end: 'CEM 01 DO GUARÁ (GG) QE 07 Área Especial M - GUARÁ I',
              turma: [
                {
                  horas: '18h',
                  turma: 'Infantil',
                  dias: 'ter. qui.',
                },
                {
                  horas: '19h',
                  turma: 'Juvenil e Adulto',
                  dias: 'ter. qui.',
                },
                {
                  horas: '20h',
                  turma: 'Juvenil e Adulto',
                  dias: 'ter. qui.',
                },
                {
                  horas: '10h',
                  turma: 'Juvenil e Adulto',
                  dias: 'sab.',
                },
              ],
            },
          ],
          foto: digao,
        },
        {
          professor: 'Mestre Pesado',
          phone: '(61) 99211-1217',
          local: [
            {
              local: 'Escola Técnica do Guará',
              end: 'QI 29 Guará II',
              turma: [
                {
                  horas: '20h',
                  turma: 'Juvenil e Adulto',
                  dias: 'ter. qui.',
                },
              ],
            },
          ],
          foto: pesado,
        },
        {
          professor: 'Mestra Lia',
          phone: '(61) 99169-9165',
          local: [
            {
              local: 'CENTRO ENSINOO MÉDIO 01',
              end: 'CEM 01 DO GUARÁ (GG) QE 07 Área Especial M - GUARÁ I',
              turma: [
                {
                  horas: '18h30',
                  turma: 'Infantil',
                  dias: 'seg. qua. sex.',
                },
              ],
            },
          ],
          foto: lia,
        },
        {
          professor: 'Mestra Dani',
          phone: '(61) 9 9211-1207',
          local: [
            {
              local: 'ESCALA AMERICANA DE BRASÍLIA',
              end: 'SGAS 605 ',
              turma: [
                {
                  horas: '15h',
                  turma: 'Infantil',
                  dias: 'ter, qui. sex.',
                },
              ],
            },
            {
              local: 'EMBAIXADA DO JAPÃO',
              end: 'SGAS 605 ',
              turma: [
                {
                  horas: '18h30',
                  turma: 'Infantil',
                  dias: 'ter.',
                },
              ],
            },
            {
              local: 'EMBAIXADA DO JAPÃO',
              end: 'SGAS 605 ',
              turma: [
                {
                  horas: '18h30',
                  turma: 'Infantil',
                  dias: 'ter.',
                },
              ],
            },
            {
              local: 'CENTRO ENSINO MEDIO 01',
              end: 'GUARÁ - CEM 01 - GG',
              turma: [
                {
                  horas: '18h30',
                  turma: 'Adultos Iniciantes',
                  dias: 'seg. qua. e sex.',
                },
              ],
            },
          ],
          foto: dani,
        },
        {
          professor: 'CONTRAMESTRE BETO',
          phone: '(61) 98418-4005',
          local: [
            {
              local: 'COLÉGIO EVEREST - Lago Sul',
              end: 'St. de Habitações Individuais Sul QI 19 Chácara 18 - Lago Sul',
              turma: [
                {
                  horas: '16h00',
                  turma: 'Infantil',
                  dias: 'seg. qua.',
                },
              ],
            },
          ],
          foto: beto,
        },
        {
          professor: 'CONTRAMESTRE Paullo Paulada',
          phone: '(61) 98114-8719',
          local: [
            {
              local: 'Escola Classe 06 Guara II',
              end: 'QE 24/26',
              turma: [
                {
                  horas: '18h30',
                  turma: 'Infantil',
                  dias: 'seg. qua. sex.',
                },
                {
                  horas: '19h30',
                  turma: 'Juvenil e Adulto',
                  dias: 'seg. qua. sex.',
                },
              ],
            },
          ],
          foto: paulada,
        },
        {
          professor: 'INSTRUTOR TOSO',
          phone: '(61) 98406-9337',
          local: [
            {
              local:
                'Centro de ensino fundamental Athos Bulcão CEFAB - Cruzeiro Novo',
              end: 'SHCES 305',
              turma: [
                {
                  horas: '18h20',
                  turma: 'Infantil',
                  dias: 'seg. qua. sex.',
                },
                {
                  horas: '19h00',
                  turma: 'Juvenil e Adulto',
                  dias: 'seg. qua. sex.',
                },
              ],
            },
            {
              local: 'Escola Mãe da Divina Providencia ',
              end: 'SHCES 801',
              turma: [
                {
                  horas: '18h20',
                  turma: 'Juvenil e Adulto',
                  dias: 'seg. qua.',
                },
              ],
            },
            {
              local: 'Universo Kids / Ultra Academia',
              end: 'Ultra Academia',
              turma: [
                {
                  horas: '18h20',
                  turma: 'Infatil',
                  dias: 'ter. qui.',
                },
              ],
            },
          ],
          foto: toso,
        },
      ],
    },
    {
      state: 'São Paulo',
      aulas: [
        {
          professor: 'MESTRE SABUGÃO',
          phone: '(11) 9 8919-8368',
          local: [
            {
              local: 'Viva Vida - Projeto social terceira idade',
              end: 'Rua Dom Luís lasagna 255 Ipiranga ',
              turma: [
                {
                  horas: '16h',
                  turma: '3ª Idade',
                  dias: 'seg. qua.',
                },
              ],
            },
            {
              local: 'Colégio São João Gualberto',
              end: 'Rua Doutor Argemiro Couto de Barros 195 Chácara Inglesa',
              turma: [
                {
                  horas: '18h30',
                  turma: 'Maternal ao Ensino Médio',
                  dias: 'ter.',
                },
              ],
            },
            {
              local: 'Colégio Costa Zavagli',
              end: 'Rua Arthur Neiva 400 Butantã',
              turma: [
                {
                  horas: '12h35',
                  turma: 'Maternal ao Ensino Médio',
                  dias: 'seg.',
                },
              ],
            },
          ],
          foto: sabugao,
        },
        {
          professor: 'CONTRAMESTRE TERZI',
          phone: '(11) 9 8919-8368',
          local: [
            {
              local: 'Colégio São João Gualberto',
              end: 'Rua Doutor Argemiro Couto de Barros 195 Chácara Inglesa',
              turma: [
                {
                  horas: '18h30',
                  turma: 'Maternal ao Ensino Médio',
                  dias: 'ter.',
                },
              ],
            },
          ],
          foto: terzi,
        },
      ],
    },
    {
      state: 'Campina Grande',
      aulas: [
        {
          professor: 'INSTRUTOR MACARRÃO',
          phone: '(83) 9 8625-2834',
          local: [
            {
              local: 'SAB DO ROCHA',
              end: 'SAB DO ROCHA',
              turma: [
                {
                  horas: '19h',
                  turma: 'Adulto',
                  dias: 'seg. qua.',
                },
              ],
            },
          ],
          foto: macarrao,
        },
        {
          professor: 'INSTRUTOR MARRUDO',
          phone: '(83) 98141-5466',
          local: [
            {
              local: 'SAB DO ROCHA',
              end: 'ONG NOSSA RAMADINHA MELHOR',
              turma: [
                {
                  horas: '19h',
                  turma: 'Juvenil  e Adulto',
                  dias: 'qui. sex.',
                },
              ],
            },
          ],
          foto: marrudo,
        },
        {
          professor: 'GRADUADO NATALINO',
          phone: '(83) 9 8714-2072',
          local: [
            {
              local: 'Fagundes',
              end: 'QUADRA DA LARANJEIRA',
              turma: [
                {
                  horas: '9h',
                  turma: 'Juvenil  e Adulto',
                  dias: 'dom.',
                },
              ],
            },
          ],
          foto: natalino,
        },
      ],
    },
  ];

  const handleClun = (estado: string, cidades: City[]): number => {
    const cidadeEncontrada = cidades.find((cidade) => cidade.state === estado);
    if (cidadeEncontrada) {
      if (cidadeEncontrada.aulas.length > 2) {
        return 3;
      } else {
        return 2;
      }
    } else {
      return 2;
    }
  };

  return (
    <div className="pb-6 sm:w-96 md:w-[600px] lg:w-[900px] xl:w-[1200px] 2xl:w-[1440px] mx-auto">
      <div className="flex justify-center space-x-4">
        {professores.map((city, index) => (
          <div
            key={`${city}+${index}`}
            className="border border-gray-500 rounded p-2 mb-5 cursor-pointer transition duration-300 hover:bg-[#025c09] hover:text-[#effef0] hover:border-gray-600 m-2"
            onClick={(event) => handleClick(event)}
          >
            {city.state}
          </div>
        ))}
      </div>
      <MasonryUI colun={handleClun(state, professores)}>
        {professores.map(
          (professor) =>
            professor.state === state &&
            professor.aulas.map((aulas, index) => (
              <div
                key={`${aulas.professor}-${index}`}
                className="bg-gradient-to-t from-[#e7e7e7] to-[#f1f1f1] p-4 border border-[#a8a8a8] rounded-lg shadow-lg md:w-[300px] lg:w-[440px] mb-3 transition duration-300 transform hover:scale-105"
              >
                <div className="flex flex-col md:flex-row items-center space-x-4">
                  <div className="md:w-2/3">
                    <div className="flex items-center mb-2">
                      <IconCalendar className="h-[24px] text-gray-500" />
                      <p className="ml-2 font-bold text-gray-500">Aulas</p>
                    </div>
                    <p className="text-lg font-bold uppercase text-gray-500">
                      {aulas.professor}
                    </p>
                    <p className="text-lg text-gray-500 uppercase mb-2">
                      {aulas.phone}
                    </p>
                  </div>
                  <div className="md:w-1/3 flex justify-center">
                    <img
                      className="rounded-lg border-[#ffffff] border h-40 w-25 object-cover"
                      src={aulas.foto}
                      alt="Imagem do Professor"
                    />
                  </div>
                </div>
                <div
                  className={`grid ${
                    aulas.local.length > 1 ? 'grid-cols-2 gap-4' : ''
                  }`}
                >
                  {aulas.local.map((local, idx) => (
                    <div
                      key={`${local.end}-${idx}`}
                      className="bg-[#ececec] border-[#a8a8a8] rounded-lg border mt-2 p-2"
                    >
                      <p className="text-[#013209] font-bold">{local.local}</p>
                      <p className="text-[#013209]">{local.end}</p>
                      {local.turma.map((t, i) => (
                        <div key={`${t.turma}-${i}`}>
                          <p className="text-[#013209] font-semibold">
                            {t.turma}
                          </p>
                          <p className="text-[#013209]">
                            {t.horas} - {t.dias}
                          </p>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            ))
        )}
      </MasonryUI>
    </div>
  );
};
