import React from 'react';
import { GraduationUI } from './ui';
import { AuthWrapper } from 'src/features/core/auth/screens/AuthWrapper';

export const GraduationScreen: React.FC = (): JSX.Element => {
  return (
    <AuthWrapper isHomepage={false}>
      <GraduationUI />
    </AuthWrapper>
  );
};
