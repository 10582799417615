import React from 'react';
import { HomeContainer } from './ui';
import { AuthWrapper } from '../../../core/auth/screens/AuthWrapper';

export const HomeScreen: React.FC = (): JSX.Element => {
  return (
    <AuthWrapper isHomepage>
      <HomeContainer />
    </AuthWrapper>
  );
};
