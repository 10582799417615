import React from 'react';
import { Route as RouteWrapper, RouteProps, Redirect } from 'react-router-dom';
import { REACT_APP_AUTH } from 'src/utils/config';
import { getBoolean } from 'src/helpers/common/localStorage';
import { path as routes } from './path';
import { toast } from 'react-toastify';

interface RouteWrapperProps extends RouteProps {
  // eslint-disable-next-line
  component: any;
  isPrivateRoute?: boolean;
  path: string;
}

export const Route: React.FC<RouteWrapperProps> = ({
  component: Component,
  isPrivateRoute = false,
  // eslint-disable-next-line
  path,
  ...rest
}: RouteWrapperProps): JSX.Element => {
  const signed = getBoolean(String(REACT_APP_AUTH));

  console.log(signed);

  if (isPrivateRoute && !signed) {
    toast.warn('Faça Login novamente.');
    return <Redirect to={routes.Initial.itself} />;
  }

  return (
    <RouteWrapper {...rest} render={(props) => <Component {...props} />} />
  );
};
