import React from 'react';
import { ArticlesUI } from './ui';
import { AuthWrapper } from 'src/features/core/auth/screens/AuthWrapper';

export const ArticlesScreen: React.FC = (): JSX.Element => {
  return (
    <AuthWrapper isHomepage={false}>
      <ArticlesUI />
    </AuthWrapper>
  );
};
