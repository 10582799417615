import React, { FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FromInputRegisterPix } from 'src/features/core/auth/types';
import validators from 'src/helpers/validators';
import useForm from 'src/hooks/useForm';
import { PixBasic } from 'src/model/UserBasic';
import { setLoading, useLoading } from 'src/redux/loading/loadingSlice';
import { CreatePixUI, NameFiles } from './ui';
import { api } from 'src/services/api';
import { Pix } from 'src/model/Pix';

export const CreatePixScreen: React.FC = (): JSX.Element => {
  const { loading } = useSelector(useLoading);
  const [formNameFiles, setFormNameFiles] = useState<NameFiles>({});
  const dispatch = useDispatch();

  const {
    formData: formDataRegister,
    formErrors: formErrorRegister,
    setErrors: setErrorRegister,
    onChangeFormInput: onChangeFormInputRegister,
    isFormValid: isFormValidRegister,
    resetForm: resetFormRegister,
  } = useForm({
    initialData: {
      numeroPix: '',
      imageBase64: '',
    },
    validators: {
      numeroPix: [validators.required],
      imageBase64: [validators.required],
    },
  });

  const handleOnChangeFileInput =
    (inputName: string) =>
    (file: File | undefined): void => {
      console.log('Arquivo selecionado:', file);
      // validate if file is image
      if (file && file.type.match(/image\/(jpg|jpeg|png)/)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result?.toString();
          if (base64) {
            setFormNameFiles({ ...formNameFiles, [inputName]: file.name });
            onChangeFormInputRegister(inputName)('');
            onChangeFormInputRegister(inputName)(base64);
          }
        };
      } else {
        setErrorRegister({
          [inputName]: ['O formato deve ser .jpg, .jpeg ou .png'],
        });
      }
    };

  const handleOnSubmitRegister = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();
      if (isFormValidRegister()) {
        dispatch(setLoading(true));
        const payload: PixBasic = {
          numeroPix: formDataRegister[FromInputRegisterPix.numeroPix],
          image: formDataRegister[FromInputRegisterPix.imageBase64],
        };

        console.log(payload);
        const response = await api.post<Pix>('/user/create-pix', payload);
        console.log(response);
        if (response.status === 201) {
          resetFormRegister();
          toast.success('Dados salvos com sucesso!');
        } else if (response.status === 400) {
          const errorMessage =
            typeof response.data === 'string'
              ? response.data
              : JSON.stringify(response.data);
          toast.error(errorMessage);
        }
      }
    } catch (error: any) {
      console.error(error);
      let errorMessage =
        'Falha ao realizar o cadastro, tente novamente mais tarde';
      if (error.response && error.response.data) {
        errorMessage =
          typeof error.response.data === 'string'
            ? error.response.data
            : JSON.stringify(error.response.data);
      }
      toast.error(errorMessage);
      setErrorRegister({
        document: [errorMessage],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <CreatePixUI
      formData={formDataRegister}
      state={loading}
      formErrors={formErrorRegister}
      onChangeFormInput={onChangeFormInputRegister}
      formNameFiles={formNameFiles}
      onSubmitRegister={handleOnSubmitRegister}
      onChangeFormFileInput={handleOnChangeFileInput}
    />
  );
};
