import React from 'react';

export const GraduationUI: React.FC = () => {
  return (
    <>
      <section className="w-full px-4">
        <div className="container mx-auto flex flex-col items-center">
          <div className="p-4 md:p-8 md:flex-shrink-0">
            <div className="text-[#013209] font-extrabold text-2xl sm:text-3xl lg:text-4xl tracking-tight text-center dark:text-white">
              SISTEMA DE GRADUAÇÃO
            </div>
            <div className="mt-2 flex flex-col md:flex-row items-center">
              <div className="text-base md:text-lg">
                <p className="mt-6 text-[#013209] dark:text-slate-400">
                  O sistema de graduação utilizada pelo Centro Cultural Arte
                  Luta N&apos;golo Capoeira, segue a mesma elaborada por
                  aspectos vivenciais e esotéricos do negro em reconhecimentos à
                  importância do seu legado sócio-histórico-cultural no Brasil.
                  Embora, haja uma relação com os domínios de irradiação dos
                  Orixás do Candomblé e da Umbanda, esta ligação é de conotação
                  meramente metafísica e filosófica, desvinculada de qualquer
                  indução ou orientação para alguma prática religiosa. (Ver
                  Idiopráxis de Capoeira - Mestre Zulu, 1995, p. 77).
                </p>
                <p className="mt-6 text-[#013209] dark:text-slate-400 font-black">
                  Cor da corda: AZUL - Categoria: ALUNO
                </p>
                <div className="bg-blue-500 w-full h-[20px]"></div>
                <p className="mt-6 text-[#013209] dark:text-slate-400">
                  - Fase Social do Negro - Fase do Negro Cativo Representa o
                  período compreendido entre o aprisionamento do negro na
                  África, o seu transporte pelo mar e sua venda em terras
                  brasileiras.
                </p>
                <p className="mt-6 text-[#013209] dark:text-slate-400">
                  - Domínio de Irradiação do Orixá: O domínio fisioesotérico é
                  de Iemanjá, que está no mar e nas areias, onde reina augusta e
                  soberana.
                </p>
                <p className="mt-6 text-[#013209] dark:text-slate-400">
                  - Relação Metafísica: As viagens dos cativos eram verdadeiros
                  martírios em alto-mar, o mar e suas areias constituem domínios
                  de Iemanjá, assim os negros cativos estavam sob a proteção
                  esotérica da soberana rainha do mar. Cor representativa de
                  Iemanjá - Azul.
                </p>
                <p className="mt-6 text-[#013209] dark:text-slate-400 font-black">
                  Cor da corda: MARROM - Categoria: ALUNO
                </p>
                <div className="bg-[#733d20] w-full h-[20px]"></div>
                <p className="mt-6 text-[#013209] dark:text-slate-400">
                  - Fase Social do Negro: Fase do negro escravo - Representa a
                  fase em que o negro é patrimônio de um senhor, submetido ao
                  trabalho escravo, perdendo toda esperança de liberdade para o
                  corpo e para a alma.
                </p>
                <p className="mt-6 text-[#013209] dark:text-slate-400">
                  - Domínio de Irradiação do Orixá: O domínio fisioesotérico é
                  de Xangô que está no trovão, nos raios e no fogo.Grande
                  guerreiro, guardião do céu e da terra.
                </p>
                <p className="mt-6 text-[#013209] dark:text-slate-400">
                  - Relação Metafísica: Desarraigar o negro de sua terra natal
                  África, significou a perda total de liberdade, estando ele
                  fora do âmbito da terra e do céu da África, Xangô não o
                  aconselharia. Cor representativa de Xangô - Marrom.
                </p>
                <p className="mt-6 text-[#013209] dark:text-slate-400 font-black">
                  Cor da corda: VERDE - Categoria: ALUNO
                </p>
                <div className="bg-[#197c1b] w-full h-[20px]"></div>
                <p className="mt-6 text-[#013209] dark:text-slate-400">
                  - Fase Social do Negro Fase do Negro Quilombola: Representa a
                  alternativa de rebeldia preferida pelos negros escravos para
                  conquistarem a liberdade. Construindo quilombos que eram
                  comunidades independentes, cada qual com sua importância e com
                  as suas peculiaridades.
                </p>
                <p className="mt-6 text-[#013209] dark:text-slate-400">
                  - Domínio de Irradiação do Orixá: O domínio fisioesotérico é
                  de Oxossi, que está nas matas, é um príncipe caçador e
                  guardião das matas, onde a maioria das vezes refugiavam-se os
                  negros.
                </p>
                <p className="mt-6 text-[#013209] dark:text-slate-400">
                  - Relação Metafísica: Os quilombos eram comunidades muito
                  dinâmicas, de grande riqueza sócio-cultural. Cada quilombo
                  tinha suas matas. Cor representativa de Oxossi: Verde.
                </p>
                <p className="mt-6 text-[#013209] dark:text-slate-400 font-black">
                  Cor da corda: AMARELA - Categoria: MONITOR
                </p>
                <div className="bg-[#d2c52d] w-full h-[20px]"></div>
                <p className="mt-6 text-[#013209] dark:text-slate-400">
                  - Fase Social do Negro Fase do Negro Capitão de Areia:
                  Representa a fase decorrente da promulgação da Lei do Ventre
                  Livre em 1871, em que os filhos das negras escravas nasciam
                  livres como as águas doces e caíam na delinqüência como as
                  águas de cachoeira, por não terem condições de se manterem
                  plenamente livres.
                </p>
                <p className="mt-6 text-[#013209] dark:text-slate-400">
                  - Domínio de Irradiação do Orixá: O domínio fisioesotérico é
                  de Oxum, que está nas cachoeiras, nos rios e nas águas doces.
                  Filha mimada de Oxalá e detentora de grande beleza e encanto.
                </p>
                <p className="mt-6 text-[#013209] dark:text-slate-400">
                  - Relação Metafísica: A Lei do Ventre Livre representa um
                  aspecto dúbio, em que a criança nascia livre como as águas
                  doces das nascentes, mas caía na delinqüência e na
                  marginalidade como as águas em queda numa cachoeira. Cor
                  representativa de Oxum – Amarela.
                </p>
                <p className="mt-6 text-[#013209] dark:text-slate-400 font-black">
                  Cor da corda: ROXA - Categoria: CONTRAMESTRE
                </p>
                <div className="bg-[#7c28a1] w-full h-[20px]"></div>
                <p className="mt-6 text-[#013209] dark:text-slate-400">
                  - Fase Social do Negro Fase do Negro Sexagenário: Representa a
                  fase advinda com a vigência da Lei dos Sexagenários que, à
                  primeira vista apresentava como uma generosa concessão, no
                  entanto faltava ao escravo sexagenário condições de vida para
                  exercer uma atividade lucrativa capaz de promover a sua
                  manutenção. Conheceu a liberdade como a dos ventos e também o
                  desafio de sobrevivência como que uma tempestade.
                </p>
                <p className="mt-6 text-[#013209] dark:text-slate-400">
                  - Domínio de Irradiação do Orixá: O domínio fisioesotérico é
                  de Iansã, uma altiva guerreira que apraz e se entrega à luta e
                  enfrenta o perigo, está nas tempestades, ventanias e coriscos.
                </p>
                <p className="mt-6 text-[#013209] dark:text-slate-400">
                  - Relação Metafísica: A Lei dos Sexagenários representou um
                  grande desafio para os sexagenários, pois foi um grande
                  confronto, liberdade semelhante à dos ventos das tempestades,
                  domínios de Iansã. Cor representativa de Iansã - Roxa.
                </p>
                <p className="mt-6 text-[#013209] dark:text-slate-400 font-black">
                  Cor da corda: VERMELHA - Categoria: MESTRE EDIFICADOR
                </p>
                <div className="bg-[#a1282c] w-full h-[20px]"></div>
                <p className="mt-6 text-[#013209] dark:text-slate-400">
                  - Fase Social do Negro Fase do Negro Liberto: Representa a
                  fase advinda com a vigência da Lei Áurea de 13 de maio de
                  1888. A sociedade da época via o negro liberto como ignorante,
                  preguiçoso e desordeiro, de ânimo viciado e moral degenerada e
                  o marginalizava. Este por sua vez, na busca pela sobrevivência
                  notabilizou-se como guerreiro, participando de maltas e de
                  empreitadas como capanga.
                </p>
                <p className="mt-6 text-[#013209] dark:text-slate-400">
                  - Domínio de Irradiação do Orixá: O domínio fisioesotérico é
                  de Ogum, que está no ferro, nos desastres, nas guerras e nas
                  demandas espirituais.
                </p>
                <p className="mt-6 text-[#013209] dark:text-slate-400">
                  - Relação Metafísica: A rivalidade entre as maltas de
                  capoeiristas eram seriíssimas e os confrontos eram sempre
                  sangrentos, porém o agravo com a polícia era geral e bem
                  maior. A atitude guerreira do negro liberto se ajusta ao
                  domínio de irradiação de Ogum, ele simboliza a energia ativa.
                  Cor representativa de Ogum – Vermelha.
                </p>
                <p className="mt-6 text-[#013209] dark:text-slate-400 font-black">
                  Cor da corda: BRANCA - Categoria: MESTRE
                </p>
                <div className="bg-white border w-full h-[20px]"></div>
                <p className="mt-6 text-[#013209] dark:text-slate-400">
                  - Fase Social do Negro Fase do Negro Cidadão: Representa a
                  fase em que o negro embora ainda em condição de inferioridade,
                  de minguamento das oportunidades, consegue reconhecer-se
                  criticamente a partir de sua inserção na sociedade e terá de
                  conquistar seus direitos universais de cidadania.
                </p>
                <p className="mt-6 text-[#013209] dark:text-slate-400">
                  - Domínio de Irradiação do Orixá: O domínio fisioesotérico é
                  de Oxalá, que tem domínio sobre a abóboda celeste, ligado ao
                  princípio de tudo, da criação, da pureza e da paz. É o chefe
                  supremo e pai de quase todas as divindades.
                </p>
                <p className="mt-6 text-[#013209] dark:text-slate-400">
                  - Relação Metafísica: Com a veiculação secular de imagens
                  estereotipadas do negro, formou-se um racismo dissimulado em
                  que o seu combate exige o envolvimento consciente de todos os
                  segmentos sociais na busca de uma cidadania plena para todos.
                  O exercício da cidadania plena está associada à universalidade
                  de irradiações de Oxalá. Cor representativa de Oxalá – Branca.
                </p>
                <hr className="mt-5" />
                <p className="mt-6 text-[#013209] mx-auto dark:text-slate-400 font-black">
                  A ordem crescente na hierarquia das cores é:
                </p>
                <p className="mt-6 text-[#013209] mx-auto dark:text-slate-400">
                  AZUL, MARROM, VERDE, AMARELA, ROXA, VERMELHA E BRANCA.
                </p>
                <p className="mt-6 text-[#013209] mx-auto dark:text-slate-400">
                  As cores são sete, contudo, o número de cordas de graduações
                  são onze, isto porque na categoria de alunos é usada a corda
                  de transição mediando a passagem de uma graduação de cor pura
                  anterior para outra de cor pura posterior – nas graduações de
                  contramestres e mestres são usadas somente cores puras (roxa,
                  vermelha, branca). A ordem crescente na hierarquia das cordas
                  de graduações é:
                </p>
                <p className="mt-6 text-[#013209] mx-auto dark:text-slate-400 font-black mb-3">
                  Sistema de Graduação
                </p>
                <div className="flex flex-col sm:flex-row mb-5 border p-2 rounded">
                  <div className="w-full sm:w-1/3 md:border-r-2 mb-2 sm:mr-1 ">
                    <p className="text-[#013209] mx-auto dark:text-slate-400 font-bold mb-2">
                      Tradicional
                    </p>
                    <div>
                      <ul>
                        <li>Azul</li>
                        <li>Azul-Marrom</li>
                        <li>Marrom</li>
                        <li>Marrom-Verde</li>
                        <li>Verde</li>
                        <li>Verde-Amarela</li>
                        <li>Amarela</li>
                        <li>Amarela-Roxa</li>
                        <li>Roxa</li>
                        <li>Vermelha</li>
                        <li>Branca</li>
                      </ul>
                    </div>
                  </div>
                  <div className="w-full sm:w-1/3 md:border-r-2 mb-2 sm:mr-1 ">
                    <p className="text-[#013209] mx-auto dark:text-slate-400 font-bold ">
                      02 a 05 anos
                    </p>
                    <div>
                      <ul>
                        <li>Crua - 1 ponteira-Azul</li>
                        <li>Crua - 1 ponteira-Marrom</li>
                        <li>Crua - 1 ponteira-Verde</li>
                        <li>Crua - 1 ponteira-Amarela</li>
                      </ul>
                    </div>
                  </div>
                  <div className="w-full sm:w-1/3 md:border-r-2 mb-2 sm:mr-1 ">
                    <p className="text-[#013209] mx-auto dark:text-slate-400 font-bold">
                      06 a 12 anos
                    </p>
                    <div>
                      <ul>
                        <li>Azul - 1 ponteira-Marrom</li>
                        <li>Azul - 2 ponteiras-Marrom</li>
                        <li>Azul - 1 ponteira-Verde</li>
                        <li>Azul - 2 ponteiras-Verde</li>
                        <li>Azul - 1 ponteira-Amarela</li>
                        <li>Azul - 2 ponteiras-Amarela</li>
                      </ul>
                    </div>
                  </div>
                  <div className="w-full sm:w-1/3">
                    <p className="text-[#013209] mx-auto dark:text-slate-400 font-bold">
                      Especial
                    </p>
                    <div>
                      <ul>
                        <li>Azul/Crua - uma ponteira-Marrom</li>
                        <li>Azul/Crua - uma ponteira-Verde</li>
                        <li>Azul/Crua - uma ponteira-Amarela</li>
                        <li>Marrom/Crua - uma ponteira-Verde</li>
                        <li>Marrom/Crua - uma ponteira-Amarela</li>
                        <li>Verde/Crua - uma ponteira-Amarela</li>
                        <li>Verde/Crua - uma ponteira-Roxa</li>
                        <li>Amarela/Crua - uma ponteira-Verde</li>
                        <li>Amarela/Crua - uma ponteira-Roxa</li>
                        <li>Roxa/Crua - uma ponteira-Vermelha</li>
                        <li>
                          Vermelha - com falha em a cada 5cm antes da Boneca
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <p className="mt-6 text-[#013209] mx-auto dark:text-slate-400 font-black">
                  O nó da corda deve ser feito buscando-se o símbolo do infinito
                  (oito deitado).
                </p>
                <p className="mt-6 text-[#013209] mx-auto dark:text-slate-400">
                  Se for do <b>sexo masculino</b> deve usar o{' '}
                  <b>nó é do lado direito</b> e{' '}
                  <b>se for do sexo feminino o nó fica do lado esquerdo</b>.
                </p>
                <p className="mt-6 text-[#013209] mx-auto dark:text-slate-400 font-black">
                  Adendo
                </p>
                <p className="mt-6 mb-5 mx-auto dark:text-slate-400">
                  Inclusão definida no Encontro Pedagógico N&apos;golo Capoeira
                  de 2015 e (não consta no livro Idiopráxis de Capoeira - Mestre
                  Zulu)
                </p>
                <div>
                  <ul>
                    <li>
                      Corda Crua - <b>Filiados</b>{' '}
                    </li>
                    <li>Graduação - para crianças de 02 a 05 anos.</li>
                    <li>
                      Corda Crua c/ a ponteira colorida na cor correspondente à
                      sua graduação - Reciclagem
                    </li>
                    <li>Graduação especial - Terceira idade e PNE.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
