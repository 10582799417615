import React from 'react';
import { Route } from '../../navigation/Route';
import { HomeScreen } from './screens/Home';
import { GroupScreen } from './screens/Group';
import { MestreDionizioScreen } from './screens/MestreDionizio';
import { GraduationScreen } from './screens/Graduation';
import { ArticlesScreen } from './screens/Articles';

export const HOME_ROUTES = {
  itself: '/',
  group: '/historia',
  mestre: '/mestre-dionizio',
  graduation: '/graduacao',
  articles: '/articles',
};

export const HomeNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route exact path={HOME_ROUTES.itself} component={HomeScreen} />
    <Route path={HOME_ROUTES.group} component={GroupScreen} />
    <Route path={HOME_ROUTES.mestre} component={MestreDionizioScreen} />
    <Route path={HOME_ROUTES.graduation} component={GraduationScreen} />
    <Route path={HOME_ROUTES.articles} component={ArticlesScreen} />
  </React.Fragment>
);
