import React from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

interface MasonryProps {
  children: React.ReactNode;
  colun: number;
}

export const MasonryUI = ({ children, colun }: MasonryProps) => {
  return (
    <ResponsiveMasonry
      columnsCountBreakPoints={{
        400: 1,
        600: 1,
        900: colun,
        1200: colun,
        1440: colun,
      }}
    >
      <Masonry>{children}</Masonry>
    </ResponsiveMasonry>
  );
};
