import { HOME_ROUTES } from 'src/features/home/navigation';
import { EVENT_ROUTES } from 'src/features/event/screens/navigation';
import { CHECKOUT_ROUTES } from 'src/features/checkout/navigation';
import { CREATEPIX_ROUTES } from 'src/features/pix/screens/navigation';
import { TICKETS_ROUTES } from 'src/features/tickets/navigation';

const path = {
  Initial: {
    ...HOME_ROUTES,
    ...EVENT_ROUTES,
    ...CHECKOUT_ROUTES,
    ...CREATEPIX_ROUTES,
    ...TICKETS_ROUTES,
    All: '*',
  },
} as const;

export { path };
