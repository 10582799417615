import React from 'react';
import { Route } from 'src/navigation/Route';
import { CheckoutScreen } from './screens/Checkout';

export const CHECKOUT_ROUTES = {
  checkout: '/inscrição/:id',
};

export const CheckoutNavigation = () => {
  return (
    <React.Fragment>
      <Route path={CHECKOUT_ROUTES.checkout} exact component={CheckoutScreen} />
    </React.Fragment>
  );
};
