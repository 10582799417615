import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AuthWrapper } from 'src/features/core/auth/screens/AuthWrapper';
import { getBoolean } from 'src/helpers/common/localStorage';
import { useDialog } from 'src/hooks/useDialog';
import { Checkout } from 'src/model/Checkout';
import { Events } from 'src/model/Event';
import { setLoading, useLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { REACT_APP_AUTH } from 'src/utils/config';
import { CheckoutUI } from './ui';
import { toast } from 'react-toastify';
import { Order } from 'src/model/Order';

interface EventParam {
  id: string;
}

export const CheckoutScreen: React.FC = (): JSX.Element => {
  const [element, setElement] = useState<HTMLElement | null>();
  const { id } = useParams<EventParam>();
  const { loading } = useSelector(useLoading);
  const [event, setEvent] = useState<Events>();
  const [img, setImg] = useState<string>();
  const [pix, setPix] = useState<string>();
  const history = useHistory();
  const [signed, setSigned] = useState<boolean>(
    getBoolean(String(REACT_APP_AUTH))
  );
  //   const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(
  //     ShouldShowModal.LOGIN
  //   );
  const { visible, onSetVisible, onToggle, title, onChangeTitle } = useDialog();

  //   const showModal = ({ value, title }: ShouldShowModalProps): void => {
  //     setShouldShowModal(value);
  //     onChangeTitle(title);
  //     onSetVisible(true);
  //   };

  //   const handleOnSignUp = () => {
  //     onSetVisible(false);
  //     showModal({
  //       value: ShouldShowModal.LOGIN,
  //       title: 'Crie a sua conta',
  //     });
  //   };

  //   const checkLogin = (): void => {
  //     if (!signed) {
  //       handleOnSignUp();
  //     }
  //   };

  const dispatch = useDispatch();

  const getEvent = async (): Promise<void> => {
    if (signed) {
      dispatch(setLoading(true));
      try {
        const response = await api.get<Checkout>(
          `/event/event-inscription/${id}`
        );

        console.log(response);
        if (response.status === 200) {
          setEvent(response.data.event);
          setImg(response.data.imagePix);
          setPix(response.data.numberPix);
        }
      } catch (error) {
        console.error('Erro ao buscar o evento:', error);
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      isAuth();
    }
  };

  const handleInscription = async (): Promise<void> => {
    if (element && !signed) {
      element.click();
    }
    if (signed) {
      try {
        dispatch(setLoading(true));

        const response = await api.post<Order>('/order', id);
        console.log(response);
        if (response.status === 201) {
          toast.success('Inscrição realizada com Sucesso!');
          const orderId = response.data.id;
          history.replace(`/ingressos/${orderId}`);
        } else if (response.status === 400) {
          const errorMessage =
            typeof response.data === 'string'
              ? response.data
              : JSON.stringify(response.data);
          toast.error(errorMessage);
        }
      } catch (error: any) {
        console.error(error);
        let errorMessage =
          'Falha ao realizar inscrição, tente novamente mais tarde';
        if (error.response && error.response.data) {
          errorMessage =
            typeof error.response.data === 'string'
              ? error.response.data
              : JSON.stringify(error.response.data);
        }
        toast.error(errorMessage);
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      isAuth();
    }
  };

  const handleCupon = (): void => {
    console.log('entrou aqui');
  };

  const isAuth = async (): Promise<void> => {
    setTimeout(async () => {
      if (getBoolean(String(REACT_APP_AUTH)) !== signed) {
        setSigned(getBoolean(String(REACT_APP_AUTH)));
        getEvent();
        return;
      } else {
        isAuth();
      }
    }, 500);
  };

  const checkPageLoaded = (): void => {
    const interval = setInterval(() => {
      const e = document.getElementById('btnDoLogin');
      if (e && interval) {
        setElement(e);
        clearInterval(interval);
      }
    }, 100);
  };

  useEffect(() => {
    getEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    checkPageLoaded();
    // checkLogin();
  }, [signed]);

  useEffect(() => {
    if (element && !signed) {
      element.click();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element]);

  return (
    <AuthWrapper isHomepage={false}>
      <CheckoutUI
        event={event}
        imagePix={img || ''}
        pixNumber={pix || ''}
        inscription={handleInscription}
        cupon={handleCupon}
      />
    </AuthWrapper>
  );
};
