import React from 'react';
import { MestreDionizioUI } from './ui';
import { AuthWrapper } from 'src/features/core/auth/screens/AuthWrapper';

export const MestreDionizioScreen: React.FC<object> = () => {
  return (
    <AuthWrapper isHomepage={false}>
      <MestreDionizioUI />
    </AuthWrapper>
  );
};
