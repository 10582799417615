import React from 'react';
import { Route } from 'src/navigation/Route';
import { EventsScreen } from './Events';
import { CreateEventScreen } from './CreateEvent';
import { EventScreen } from './Event';

export const EVENT_ROUTES = {
  events: '/eventos',
  event: '/evento/:id',
  createEvent: '/eventos-create',
};

export const EventNavigation = () => {
  return (
    <React.Fragment>
      <Route path={EVENT_ROUTES.events} exact component={EventsScreen} />
      <Route
        path={EVENT_ROUTES.createEvent}
        exact
        component={CreateEventScreen}
        isPrivateRoute={true}
      />
      <Route path={EVENT_ROUTES.event} exact component={EventScreen} />
    </React.Fragment>
  );
};
