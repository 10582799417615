import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AuthWrapper } from 'src/features/core/auth/screens/AuthWrapper';
import type { Events } from 'src/model/Event';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { EventsUI } from './ui';
import { useHistory } from 'react-router-dom';

export const EventsScreen: React.FC = (): JSX.Element => {
  const [event, setEvent] = useState<Events[]>([]);
  const history = useHistory();
  //   const { loading } = useSelector(useLoading);
  const dispatch = useDispatch();

  const handleEvent = async (): Promise<void> => {
    dispatch(setLoading(true));
    const { data } = await api.get<Events[]>('/event');
    setEvent(data);
    console.log(data);
    dispatch(setLoading(false));
  };

  const handleInscriton = (id: string): void => {
    console.log(id);
    history.replace(`/evento/${id}`);
  };

  useEffect(() => {
    handleEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthWrapper isHomepage={false}>
      <EventsUI event={event} inscription={handleInscriton} />
    </AuthWrapper>
  );
};
