import React from 'react';
import { Route } from 'src/navigation/Route';
import { CreatePixScreen } from './CreatePix';

export const CREATEPIX_ROUTES = {
  createPix: '/criar-pix',
};

export const CreatePixNavigation = (): JSX.Element => {
  return (
    <React.Fragment>
      <Route
        path={CREATEPIX_ROUTES.createPix}
        component={CreatePixScreen}
        exact
        isPrivateRoute={true}
      />
    </React.Fragment>
  );
};
